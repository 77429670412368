import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import Container from "../components/container/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import BlockContent from "../components/block-content";
import * as styles from "./onboarding.module.scss";
import BackButton from "../components/back-button/back-button";

export interface OnboardingPageModel {
  title: string;
  _rawDescription: any;
  _rawAutomaticDescription: any;
  _rawAutomaticSummary: any;
  _rawManualDescription: any;
  _rawManualSummary: any;
}

export const query = graphql`
  query OnboardingPageQuery {
    site: sanityOnboardingPage {
      _rawDescription
      _rawAutomaticDescription
      _rawAutomaticSummary
      _rawManualDescription
      _rawManualSummary
      title
    }
  }
`;

const ManualSending = ({ manualDescription }) => (
  <div className={styles.descriptionContainer}>
    <BlockContent blocks={manualDescription}></BlockContent>
  </div>
);

const AutomaticSending = ({ automaticDescription }) => (
  <div className={styles.descriptionContainer}>
    <BlockContent blocks={automaticDescription}></BlockContent>
  </div>
);

const OnboardingPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site: OnboardingPageModel = (data || {}).site;

  const [selectedTutorial, setSelectedTutorial] = useState(null);

  const handleClick = tutorial => {
    setSelectedTutorial(tutorial);
  };

  const handleBack = () => {
    setSelectedTutorial(null);
  };

  return (
    <Layout>
      <SEO title={site.title} />
      <Container role="main">
        <div>
          <h1>{site.title}</h1>
          {selectedTutorial ? (
            <div>
              <BackButton onClick={handleBack} />
              {selectedTutorial === "ManualSending" && (
                <ManualSending manualDescription={site._rawManualDescription} />
              )}
              {selectedTutorial === "AutomaticSending" && (
                <AutomaticSending automaticDescription={site._rawAutomaticDescription} />
              )}
            </div>
          ) : (
            <div className={styles.boxContainer}>
              <div className={styles.boxStyle}>
                <BlockContent blocks={site._rawAutomaticSummary}></BlockContent>
                <div className={styles.buttonContainer}>
                  <button
                    onClick={() => handleClick("AutomaticSending")}
                    className="primary-link-btn"
                  >
                    More info
                  </button>
                </div>
              </div>
              <div className={styles.boxStyle}>
                <BlockContent blocks={site._rawManualSummary}></BlockContent>
                <div className={styles.buttonContainer}>
                  <button onClick={() => handleClick("ManualSending")} className="primary-link-btn">
                    More info
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>
    </Layout>
  );
};

export default OnboardingPage;
